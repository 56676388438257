import { useState } from 'preact/hooks';
import { Router,Route } from 'preact-router';
import { ChakraProvider } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'
import Header from './header';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Login from '../routes/login';
import Profile from '../routes/profile';
import IntroScreen from '../routes/introscreen';
import Post from '../routes/post';
import Payment from '../routes/payment';
import Thanks from '../routes/thanks';
import ForgetPass from '../routes/forgetpass';
import Signup from '../routes/signup';
import NewPost from '../routes/newPost';

const queryClient = new QueryClient()

const App = () => {
	let currentLocalSession = localStorage.getItem("showHeader");
	if(currentLocalSession == null || currentLocalSession == undefined){
		localStorage.setItem("showHeader",0);
	}
	const [sessionInfo,setSessionInfo]=useState(currentLocalSession)
	return (
		<QueryClientProvider client={queryClient}>
			<ChakraProvider id="app">
				<Header session={sessionInfo} onHandleSession={setSessionInfo}/>
				<Router>
					<Route path="/" component={Home} />
					<Route path="/intro" component={IntroScreen} />
					<Route path="/login" component={Login} onHandleSession={setSessionInfo}/>
					<Route path="/profile" component={Profile} />
					<Route path="/post/:slug" component={Post} />
					<Route path="/payment" component={Payment} />
					<Route path="/thanks" component={Thanks} />
					<Route path="/signup" component={Signup} onHandleSession={setSessionInfo}/>
					<Route path="/forgetpass" component={ForgetPass} onHandleSession={setSessionInfo}/>
					<Route path="/newpost" component={NewPost} />
				</Router>
			</ChakraProvider>
			{/* <ReactQueryDevtools /> */}
		</QueryClientProvider>
	)
}
export default App;
