import { 
	useState, useContext
} from 'preact/hooks';
import { Link } from 'preact-router/match';
import { route } from 'preact-router';
import style from './style.css';
import { 
	HamburgerIcon,
	CloseIcon,
	Search2Icon
} from '@chakra-ui/icons';
import {  
	Input,
	InputGroup,
	InputRightElement,
	Slide, 
	Button,
	Box,
	List,
	ListItem,
	Text,
	Image,
	Flex,
	Spacer,
	Center
} from '@chakra-ui/react'
const Header = (props) => {
	const { session,onHandleSession } = props;
	const [show,setShow] = useState(false);
	const toggleSideBar = () => {
		setShow( ()=> {
			return !show
		})
	}
	const handleLogoutBtn = ()=> {
		toggleSideBar()
		fetch(`https://chambersofhealth.serviotquezada.workers.dev/logout`,{
			mode:"no-cors",
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				refresh_token: localStorage.getItem("refresh_token")
			})
		})
		localStorage.setItem("showHeader",false);
		onHandleSession(false);
		// "refresh_token": "gmPd...8wuB"
		route("/login")
	}
	
	return (
		<header class={style.header} style={{display: (session == true) ? "block":"none"}}>
			<Flex pr={"4%"} pl={"4%"}>
				<Box p={"4%"}>
					<HamburgerIcon className={style["headerham"]} onClick={toggleSideBar} boxSize={10} color={"#f9f9f9"} />
				</Box>
				<Spacer />
				<Box p={"4%"}>
					<Image srcSet='/assets/img/logo_app_blanco.svg' boxSize={12} />
				</Box>
			</Flex>
			<SlideEx isOpen={show} onClose={toggleSideBar} onHandleLogut={handleLogoutBtn} />
		</header>
	);
}

export default Header;

  function SlideEx(props) {

	const { isOpen,onClose,onHandleLogut } = props
	return (
		<Slide direction='left' in={isOpen} style={{ zIndex: 10 }}>
		  <Box className={style["sideMenuBar"]} textAlign={"right"} pt="3%" pr="5%">
			<CloseIcon onClick={onClose} color="white" boxSize={6} mt="2%" />
		  </Box>
		  <Box
			mt={"-2px"}
			h={"100%"}
			p={"10%"}
			textAlign={"center"}
			className={style["sideMenuBar"]}
		  >
			<InputGroup borderColor={"white"} w={"100%"} pl={"5%"} pr={"3%"} fontFamily={"open sans"}>
				<InputRightElement
					pointerEvents='none'
					children={<Search2Icon color={"white"} boxSize={6} mr={["65%","80%"]} />}
				/>
				<Input type='text' minW={"100%"} minH={"50px"} color={"white"} placeholder={"SEARCH"} _placeholder={{ opacity: 1, color: 'white',fontWeight:"300",letterSpacing:"3px" }} />
			</InputGroup>
			<List spacing={5} pt={"10%"}>
				<ListItem>
					<Link href={"/"} onClick={onClose}><Text className={style["listItems"]} fontSize='4xl'> News Feed</Text></Link>
				</ListItem>
				<ListItem>
					<Link href={"/profile"} onClick={onClose}><Text className={style["listItems"]} fontSize='4xl'> Profile </Text></Link>
				</ListItem>
				<ListItem>
					<Link href={"/newpost"} onClick={onClose}><Text className={style["listItems"]} fontSize='4xl'> add post </Text></Link>
				</ListItem>
			</List>
			<Button mt={["40%","70%"]} onClick={onHandleLogut} className={style["logoutBtn"]}>
				<Text className={style["logoutBtnText"]}> Log out </Text>
			</Button>
			<Center>
				<Image srcSet='/assets/img/logo_app_blanco.svg' height={"12rem"} pt={["15%","25%"]} pb={"10%"} />
			</Center>
		  </Box>
		</Slide>
	)
  }
